<template>
  <div class="messages">
    <div class="card recommond-my">
      <div class="text-grey-dark title-md text-bold">
        {{ $t("gold_history") }}
      </div>
      <!-- <el-row :gutter="20" style="margin-top: 20px">
        <el-col :span="4" :offset="6">
          <el-select
            v-model="selectedChangeType"
            :placeholder="$t('choose_change_type')"
          >
            <el-option
              v-for="(item, index) in changeTypeOpts"
              :key="index"
              :label="item.text"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="filterChangeType"
            >{{ $t("search") }}</el-button
          >
        </el-col>
      </el-row> -->

      <el-table
        :stripe="true"
        :data="list"
        v-loading="loading"
        style="width: 100%; margin-top: 10px"
        element-loading-background="rgba(0, 0, 0, 0)"
        @filter-change="tableChangeType"
      >
        <el-table-column
          :show-overflow-tooltip="true"
          prop="dateline"
          :label="$t('change_time')"
          width="200"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="quantity"
          :label="$t('change_num')"
          width="120"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="description"
          :label="$t('description')"
          width="240"
        >
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="status"
          :label="$t('related_status')"
          width="170"
        >
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.status !== '-'"
              :type="getScopType(scope.row.status)"
              effect="dark"
              size="mini"
              >{{ scope.row.status }}</el-tag
            >
            <span v-if="scope.row.status === '-'">-</span>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="type"
          :label="$t('change_type')"
          width="120"
          column-key="changeType"
          :filters="changeTypeOpts"
          :filter-multiple="false"
        >
          <template slot-scope="scope">
            <el-tag :type="getType(scope.row.type)" effect="dark" size="mini">{{
              scope.row.type
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="category"
          :label="$t('category')"
          width="120"
        >
        </el-table-column>
      </el-table>

      <div class="mt-20">
        <el-pagination
          background
          layout="prev, pager, next,total"
          :total="pagination.totalSize"
          :page-size="pagination.pageSize"
          :current-page="pagination.currentPage"
          @current-change="changePage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<style src="./index.scss" lang="scss"></style>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: false,
      list: [],
      pagination: {
        pageSize: 15,
        totalSize: 0,
        currentPage: 1
      },
      selectedChangeType: 0,
      changeTypeOpts: [
        //{
        //  value: 0,
        //  text: this.$t("choose_all")
        //},
        {
          value: 1,
          text: this.$t("income_1")
        },
        {
          value: 2,
          text: this.$t("spend")
        }
      ]
    };
  },
  async created() {
    this.getList();
  },
  methods: {
    changePage(v) {
      this.getList(v);
    },
    async getList(v) {
      this.loading = true;
      const res = await this.$http.get(
        `/api/v1/Accounts/${this.account.id}/Points`,
        {
          params: {
            page: v || this.pagination.currentPage,
            size: this.pagination.pageSize,
            changeType: this.selectedChangeType
          }
        }
      );
      this.list = res.data.list;
      this.pagination = res.data.pagebar;
      this.loading = false;
    },
    filterChangeType() {
      this.getList();
    },
    tableChangeType(filters) {
      this.selectedChangeType = 0 || filters.changeType[0];
      this.getList(1);
    },
    getScopType(status) {
      switch (status) {
        case this.$t("quota_full"):
        case this.$t("not_match"):
          return "danger";
        case this.$t("success_finish"):
          return "success";
        case this.$t("quit_midway"):
          return "";
        default:
          return "info";
      }
    },
    getType(status) {
      switch (status) {
        case this.$t("spend"):
          return "danger";
        case this.$t("income_1"):
          return null;
        default:
          return "info";
      }
    }
  },
  computed: {
    ...mapGetters(["account"])
  }
};
</script>
